import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.20.0_next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-_znq4mrgqk3jyhq2fj6g3on2hbe/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-experimental-6067_oijyjiutvcgxp2e7xyejxait3q/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-experimental-6067_oijyjiutvcgxp2e7xyejxait3q/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoggedInButton","SignInButton"] */ "/vercel/path0/src/features/auth/SignInButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopVerticalMenu"] */ "/vercel/path0/src/features/navigation/DesktopVerticalMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileDropdownMenu"] */ "/vercel/path0/src/features/navigation/MobileDropdownMenu.tsx");
