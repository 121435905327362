"use client";
import { usePlausible } from "next-plausible";
import { useCallback } from "react";

export default function useTrack() {
  const plausible = usePlausible();

  const track = useCallback(
    (
      event: string,
      props: Record<string, string | undefined | null | number | boolean>,
    ) => {
      plausible(event, { props });
    },
    [plausible],
  );

  return {
    track,
    plausible,
  };
}
