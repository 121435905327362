"use client";
import useTrack from "@/lib/tracking";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/utils/buttonVariants";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import React from "react";

export type ButtonProps = {
  asChild?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> &
  PropsWithChildren<{
    animationClass?: "";
  }>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const { track } = useTrack();

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        onClick={(e) => {
          track(`Click Button`, {
            id: props.id,
            name: props.name,
            title: props.title,
            about: props.about,
          });
          if (props.onClick) props.onClick(e);
        }}
      />
    );
  },
);
Button.displayName = "Button";

export default Button;
